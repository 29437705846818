/* eslint-disable @typescript-eslint/no-floating-promises */

import {IControllerConfig, IWidgetControllerConfig} from '@wix/native-components-infra/dist/es/src/types/types';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';
import {ProductPriceBreakdown} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductPriceBreakdown/ProductPriceBreakdown';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import _ from 'lodash';
import {ProductOptionType} from '@wix/wixstores-graphql-schema';
import {BI_APP_NAME, Experiments, origin} from '../constants';
import {actualPrice, isPreOrder} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ProductsService} from '../services/ProductsService';
import {AddToCartService} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/AddToCartService';
import {
  IGalleryStyleParams,
  ImageModeId,
  ImageRatioId,
  IPropsInjectedByViewerScript as IGalleryPropsInjectedByViewerScript,
  ProductOptionsShowOptionsOption,
  ProductsManifest,
} from '../types/galleryTypes';
import {
  BI_PRODUCT_OPTION_ACTION,
  BI_PRODUCT_OPTION_TYPE,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';
import {ProductsVariantInfoMap} from '../services/ProductsOptionsService';
import {IPropsInjectedByViewerScript as ISliderGalleryPropsInjectedByViewerScript} from '../types/sliderGalleryTypes';
import {getStylesValues} from '@wix/tpa-settings';
import stylesParams from '../components/GridGallery/stylesParams';
import {clickOnProductOptionSf, clickShippingInfoLinkSf} from '@wix/bi-logger-ec-sf/v2';

export interface IGalleryConfig {
  config: IWidgetControllerConfig['config'];
}

export abstract class BaseGalleryStore {
  protected readonly config: IWidgetControllerConfig['config'];
  protected readonly publicData: IControllerConfig['publicData'];

  protected productPriceBreakdown: ProductPriceBreakdown;
  protected productsManifest: ProductsManifest = {};
  protected productsService: ProductsService;
  protected addToCartService: AddToCartService;
  protected styles;

  constructor(
    {config}: IGalleryConfig,
    protected readonly siteStore: SiteStore,
    protected styleParams: IGalleryStyleParams,
    protected readonly updateComponent: (
      props: Partial<IGalleryPropsInjectedByViewerScript> | Partial<ISliderGalleryPropsInjectedByViewerScript>
    ) => void
  ) {
    this.config = config;
    this.publicData = _.cloneDeep(this.config.publicData);
    this.updateStyles(this.config.style.styleParams as any);

    //todo: COMPONENT === null is not tested, be this check can be removed after bolt will stop sending nulls https://wix.slack.com/archives/CAKBA7TDH/p1555852184003900
    /* istanbul ignore next: hard to test */
    if (this.config.publicData.COMPONENT === null || this.config.publicData.COMPONENT === undefined) {
      this.config.publicData.COMPONENT = {};
    }
  }

  protected updateStyles(newStyleParams: IGalleryStyleParams) {
    this.styles = getStylesValues(newStyleParams as any, stylesParams);
  }

  protected getCommonPropsToInject() {
    return {
      htmlTags: this.htmlTags,
      priceBreakdown: this.priceBreakdown,
      sendClickShippingInfoLinkSf: this.sendClickShippingInfoLinkSf.bind(this),
      imageMode: this.imageMode,
      imageRatio: this.imageRatio,
      isSEO: this.siteStore.seo.isInSEO(),
    };
  }

  protected sendClickShippingInfoLinkSf(productId: string) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.webBiLogger.report(clickShippingInfoLinkSf({productId}));
  }

  protected getCommonPropsToUpdate() {
    const htmlTags = this.htmlTags;
    const productsVariantInfoMap = this.productsVariantInfoMap;
    const imageMode = this.imageMode;
    const imageRatio = this.imageRatio;

    return {htmlTags, productsVariantInfoMap, imageMode, imageRatio};
  }

  protected get priceBreakdown() {
    return {
      shouldRenderTaxDisclaimer: this.productPriceBreakdown.shouldShowTaxDisclaimer,
      shippingDisclaimer: this.productPriceBreakdown.shippingDisclaimer,
      taxDisclaimer: this.productPriceBreakdown.taxDisclaimer,
    };
  }

  protected get shouldUseYoshiStyleParamStandardInController(): boolean {
    return this.siteStore.experiments.enabled(Experiments.GalleryUseYoshiStyleParamStandardInController);
  }

  protected get imageMode(): ImageModeId {
    /* istanbul ignore next: will be removed when spec is merged */
    return this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_imageMode
      : this.styleParams.numbers.gallery_imageMode;
  }

  protected get imageRatio(): ImageRatioId {
    /* istanbul ignore next: will be removed when spec is merged */
    return this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.galleryImageRatio
      : this.styleParams.numbers.galleryImageRatio;
  }

  protected get htmlTags() {
    return {
      productNameHtmlTag: this.publicData.COMPONENT.gallery_productNameHtmlTag || HeadingTags.H3,
      headerTextHtmlTag: this.publicData.COMPONENT.gallery_headerTextHtmlTag || HeadingTags.H2,
    };
  }

  protected handleOptionSelectionsChange(params: {
    productId: string;
    selectionIds: number[];
    optionType: ProductOptionType;
  }) {
    if (this.getIsOptionsRevealEnabled()) {
      this.productsService.clearSelections();
    }

    this.productsService.handleProductsOptionsChange(params);

    if (this.siteStore.experiments.enabled(Experiments.PreOrderClient)) {
      const {product, variant} = this.productsService.getProductAndVariantById(params.productId);

      this.productsManifest[product.id].addToCartState = this.addToCartService.getButtonState({
        price: actualPrice(product),
        inStock: product.isInStock,
        isPreOrderState: this.siteStore.experiments.enabled(Experiments.PreOrderClient) && isPreOrder(product, variant),
      });

      this.updateComponent({
        productsManifest: this.productsManifest,
        productsVariantInfoMap: this.productsVariantInfoMap,
        productsPriceRangeMap: this.productsService.productPriceRangeMap,
      });
    } else {
      this.updateComponent({
        productsVariantInfoMap: this.productsVariantInfoMap,
        productsPriceRangeMap: this.productsService.productPriceRangeMap,
      });
    }

    this.sendClickOnProductOptionBiEvent(params);
  }

  protected getIsOptionsRevealEnabled(): boolean {
    const productOptionsShowOptions = this.shouldUseYoshiStyleParamStandardInController
      ? this.styles.gallery_productOptionsShowOptions
      : this.styleParams.numbers.gallery_productOptionsShowOptions;

    return (
      !this.siteStore.isMobile() &&
      productOptionsShowOptions === ProductOptionsShowOptionsOption.REVEAL &&
      this.siteStore.experiments.enabled(Experiments.GalleryProductOptionsVisibilitySettings)
    );
  }

  private sendClickOnProductOptionBiEvent(params: {productId: string; optionType: ProductOptionType}) {
    const {productId, optionType} = params;

    const optiontype = (
      {
        [ProductOptionType.DROP_DOWN]: BI_PRODUCT_OPTION_TYPE.LIST,
        [ProductOptionType.COLOR]: BI_PRODUCT_OPTION_TYPE.COLOR,
      } as const
    )[optionType];

    const {productType} = this.productsService.getProduct(productId);

    this.siteStore.webBiLogger.report(
      clickOnProductOptionSf({
        action: BI_PRODUCT_OPTION_ACTION.CHECKED,
        appName: BI_APP_NAME,
        optiontype,
        productId,
        productType,
        origin,
        viewMode: this.siteStore.biStorefrontViewMode,
      })
    );
  }

  protected get productsVariantInfoMap(): ProductsVariantInfoMap {
    return this.shouldShowProductOptions
      ? this.productsService.getVariantInfoMap(this.siteStore.experiments.enabled(Experiments.PreOrderClient))
      : {};
  }

  protected abstract get shouldShowProductOptions(): boolean;
}
